<style scoped lang="scss">

  .signature-file-input {
    display: none;
  }

  .preview {
    padding: 20px;
    img {
      /*max-width: 80%;*/
      max-height: 270px;
    }
  }

  button.select-btn {
    background-color: #eae9ee;
    color: #8a8a8a;
    border: 2px solid #e1e0e5;
    margin: 15px 30px;
    padding: 8px 14px;
    border-radius: 3px;
    outline: none;
    &:hover {
      cursor: pointer;
    }
  }

</style>

<template>

  <div class="load-signature">

    <div class="preview">
      <img ref="preview" src="" alt="" :style="{ 'max-height' : imageHeight}">
    </div>

    <input @change="onSignatureFileSelected" ref="signatureFileInput" type="file" class="signature-file-input" accept="image/x-png,image/gif,image/jpeg">
    <v-btn color="primary" small dark depressed @click.prevent="$refs.signatureFileInput.click()">
      Select signature image
    </v-btn>

  </div>

</template>

<script>

  export default {
    name: 'VueLoadSignature',
    data() {
      return {
        selectedFile: null,
        imageData: null
      }
    },

    props: ['height'],

    computed: {
      imageHeight() {
        return (this.height - 100).toString() + 'px'
      }
    },

    methods: {

      onSignatureFileSelected(event) {
        this.selectedFile = event.target.files[0]

        let preview = this.$refs.preview
        let reader = new FileReader()
        reader.readAsDataURL(this.selectedFile)

        reader.addEventListener(
          'load',
          () => {
            this.imageData = reader.result
            preview.src = reader.result
            this.$emit('onEnd')
          },
          false
        )
      },

      saveSignature() {
        return {
          isEmpty: this.isEmpty(),
          data: this.imageData
        }
      },

      isEmpty() {
        return this.imageData === null || this.imageData === ''
      }
    }
  }

</script>
