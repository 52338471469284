<template>
  <div>
    <div class="auto-signature">
      <div class="result-section">
        <div class="result" :style="{ 'font-family' : selectedFont }">{{ signatureTextProp}}</div>
        <canvas style="display: none" ref="textCanvas" height="130" width="455"></canvas>
      </div>
    </div>
    <v-btn color="primary" small dark depressed @click.prevent="signatureSelectVisible=true" v-if="signatureTextProp">
      Select signature style
    </v-btn>
    <div v-if="signatureSelectVisible" v-click-outside="outSideClicked" class="select-section" ref="selectSection">
      <div class="styles">
        <div v-for="(font,index) in fonts" :key="index">
          <div @click="selectFont(font,index)">
            <fieldset :class="{ active : font.selected, fieldset : true}">
              <legend>Style {{ index+1 }}</legend>
              <div :style="{ 'font-family' : font.name}">{{ signatureTextProp || 'Test Signature' }}</div>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import clickOutSide from '../directives/click-outside'

export default {
  name: "VueAutoSignature",
  data() {
    return {
      signatureText: null,
      signatureSelectVisible: false,
      fonts: [{
        name: 'CollectionFree',
        selected: true
      }, {
        name: 'DerickChetty',
        selected: false
      }, {
        name: 'Everything',
        selected: false
      }, {
        name: 'Lemon',
        selected: false
      }, {
        name: 'Moonbright',
        selected: false
      }, {
        name: 'Welcome',
        selected: false
      }, {
        name: 'Lemon',
        selected: false
      }, {
        name: 'Moonbright',
        selected: false
      }, {
        name: 'Welcome',
        selected: false
      }, ],
      signatureImage: null
    }
  },
  watch: {
    signatureTextProp() {
      this.signatureText = this.signatureTextProp
      this.$emit('onEnd')
    }
  },
  props: {
    signatureTextProp: {
      type: String
    }
  },

  directives: {
    'click-outside': clickOutSide
  },

  created() {
    this.signatureText = this.signatureTextProp 
  },

  mounted() {
    // Wait 2 seconds in order canvas is drawn correctly
    const _this = this;    
    setTimeout(function(){
      _this.$emit('onEnd')
    }, 500);
  },

  computed: {
    selectedFont() {
      let f = this.fonts.filter((font) => {
        return font.selected === true
      })

      if (f.length > 0) {
        return f[0].name
      }
      return 'sans-serif';
    }
  },
  methods: {
    selectFont(font, index) {
      this.signatureSelectVisible = false;
      this.fonts.map((item) => {
        item.selected = false;
      })
      this.fonts[index].selected = true;
      this.$emit('onEnd')
    },
    saveSignature() {
    
      // let tCtx = document.getElementById('textCanvas').getContext('2d');
      let tCtx = this.$refs.textCanvas.getContext('2d');
      tCtx.clearRect(0, 0, tCtx.canvas.width, tCtx.canvas.height);
      tCtx.font = '40px ' + this.selectedFont;
      tCtx.fillText(this.signatureText, 10, tCtx.canvas.height * 3 / 4);
      this.signatureImage = tCtx.canvas.toDataURL()

      return {
        isEmpty: this.isEmpty(),
        data: this.signatureImage
      }

    },
    isEmpty() {
      return this.signatureText === null || this.signatureText === ''
    },

    outSideClicked() {
      this.signatureSelectVisible = false;
    }
  }
}
</script>
<style scoped lang="scss">
.auto-signature {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .result-section {
    .signature-input {
      width: 200px;
      border: none;
      border-bottom: 1px solid #d6d6d6;
      outline: #b9b9b9;
      margin-left: 30px;
      margin-top: 10px;
    }
    .result {
      font-size: 60px;
      padding: 30px;
      @media (max-width: 576px) {
        padding: 0px 10px;   
        font-size: 40px;
      }
    }
  }
}

.select-section {
  max-height: 330px;
  position: absolute;
  top: 120px;
  width: 300px;
  padding: 20px;
  background-color: #f6f6f6;
  padding-bottom: 10px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  box-shadow: 2px 2px 10px #888888;
  .styles {
    height: 200px;
    overflow: auto;
  }
  .active {
    background-color: #dde4e5;
  }
  .fieldset {
    border: 2px solid #2196F3;
    border-radius: 8px;
    margin-bottom: 10px;
    max-width: 400px;
    min-width: 200px;
    div {
      font-size: 30px;
    }
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
